import {
  bodyClass,
  bodyFormatedClass,
  bodyHeaderClass,
  bodyParentClass,
} from "./../tailwindcss/css";
import { imageClass, imageParentClass } from "./../tailwindcss/css";

export default function Home() {
  return (
    <>
      <HeaderImage />
      <Body />
    </>
  );
}

function Body() {
  return (
    <>
      <div class={bodyParentClass}>
        <p class={bodyHeaderClass} id="home-title">
          Piper's Vlogs
        </p>
      </div>
      <div class={bodyParentClass}>
        <p class={bodyClass} id="home-greeting">
          Hey everyone, my name is Piper!
        </p>
      </div>
      <div class={bodyParentClass}>
        <p class={bodyFormatedClass} id="home-info">
          I recently moved to Washington. I am getting my family to create a YouTube channel, and
          wanted to document my journey here. My dad is currently working on building my page so
          bare with me while I get started with content. In the meantime checkout some of my
          favorite YT channels in the links below. Also make sure to check out my YT channel, and
          subscribe!
        </p>
      </div>
    </>
  );
}

function HeaderImage() {
  return (
    <div class={imageParentClass}>
      <img
        class={imageClass}
        src="https://nationaltoday.com/wp-content/uploads/2020/04/unicorn-1-1-1200x834.jpg"
        alt="unicorn logo"
        id="unicorn-logo"
      />
    </div>
  );
}
