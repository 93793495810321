import React from "react";
import { BrowserRouter as Router, Switch, Route, Link, useRouteMatch } from "react-router-dom";
import "tailwindcss/tailwind.css";
import "./App.css";
import { appClass, linkClass, linkClassSelected, routingClass } from "./tailwindcss/css";
import ContactMe from "./components/ContactMe";
import AboutMe from "./pages/about-me";
import Videos from "./pages/videos";
import { FavoriteYouTubers, MyChannel } from "./components/YouTubers";
import Home from "./pages/home";

export default function App() {
  return (
    <Router>
      <div>
        <div class={routingClass}>
          <MenuLink activeOnlyWhenExact={true} to="/" label="Home" />
          <MenuLink to="/about" label="About Me" />
          <MenuLink to="/videos" label="Videos" />
        </div>
        <Switch>
          <Route exact path="/">
            <HomeLink />
          </Route>
          <Route path="/about">
            <AboutMeLink />
          </Route>
          <Route path="/videos">
            <VideosLink />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

function MenuLink({ label, to, activeOnlyWhenExact }) {
  let match = useRouteMatch({
    path: to,
    exact: activeOnlyWhenExact,
  });

  return (
    <Link
      class={match ? `${linkClassSelected} btn btn-primary` : `${linkClass} btn btn-primary`}
      to={to}
    >
      {label}
    </Link>
  );
}

function HomeLink() {
  return (
    <div class={appClass}>
      <Home />
      <MyChannel />
      <FavoriteYouTubers />
      <ContactMe />
    </div>
  );
}

function AboutMeLink() {
  return (
    <div class={appClass}>
      <AboutMe />
      <MyChannel />
      <ContactMe />
    </div>
  );
}

function VideosLink() {
  return (
    <div class={appClass}>
      <Videos />
      <ContactMe />
    </div>
  );
}
