import "./../App.css";

/* app classes */
export const appClass =
  "flex flex-col text-center items-center content-center justify-center bg-piperback";

/* body classes */
export const bodyParentClass =
  "flex justify-center md:container md:mx-auto border-2 border-piperback p-2";
export const bodyHeaderClass = "text-piperpink text-5xl";
export const bodyClass = "text-piperpink text-2xl";
export const bodyFormatedClass = `text-piperpink text-base max-w-md lg:max-w-none text-left`;

/* image classes */
export const imageClass = "max-h-sm max-w-sm";
export const imageParentClass =
  "flex flex-col items-center content-center justify-center bg-piperback p-4";

/* link classes */
export const linkClass = "text-piperpink text-decoration: underline";
export const linkParentClass =
  "flex items-center justify-center grid gap-2 grid-cols-3 text-base max-w-screen-sm";
export const linkImageClass = "rounded-full max-h-15 max-w-15";
export const linkClassSelected = "text-piperpink";

/* routing classes */
export const routingClass =
  "flex grid-col-3 gap-3 items-center justify-center text-base max-w-md lg:max-w-none pt-4";

/* page classes */
export const pageClass =
  "flex flex-col text-center items-center content-center justify-center bg-piperback p-4";

/* video classes */
export const videoPlayerClass = "max-w-xs lg:max-w-none pb-4";
