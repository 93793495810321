import { linkParentClass } from "../tailwindcss/css";
import { jester, lyssynoel, piper, sssniperwolf } from "../youtubers/youtubers";
import { LinkImage } from "./LinkFactory";

export function FavoriteYouTubers() {
  return (
    <span class={linkParentClass}>
      <LinkImage name={lyssynoel.name} id={lyssynoel.id} url={lyssynoel.url} img={lyssynoel.img} />
      <LinkImage name={jester.name} id={jester.id} url={jester.url} img={jester.img} />
      <LinkImage
        name={sssniperwolf.name}
        id={sssniperwolf.id}
        url={sssniperwolf.url}
        img={sssniperwolf.img}
      />
    </span>
  );
}

export function MyChannel() {
  return (
    <span class={`${linkParentClass} p-4`}>
      <span />
      <LinkImage name={piper.name} id={piper.id} url={piper.url} img={piper.img} />
      <span />
    </span>
  );
}
