import { linkClass, linkImageClass } from "../tailwindcss/css";

export function LinkImage(props) {
  return (
    <div>
      <a class={linkClass} href={props.url} target="_blank" rel="noopener noreferrer" id={props.id}>
        <img
          class={linkImageClass}
          src={props.img}
          alt={`${props.id} thumbnail`}
          id={`${props.id}-thumbnail`}
        />
        {props.name}
      </a>
    </div>
  );
}

export function Link(props) {
  return (
    <div>
      <a
        class={linkClass}
        href={props.url}
        target="_blank"
        rel="noopener noreferrer"
        id={props.name}
      >
        {props.name}
      </a>
    </div>
  );
}
