import {
  bodyClass,
  bodyFormatedClass,
  bodyHeaderClass,
  bodyParentClass,
  pageClass,
} from "../tailwindcss/css";

export default function AboutMe() {
  return (
    <div class={pageClass}>
      <div class={bodyParentClass}>
        <p class={bodyHeaderClass} id="about-me-title">
          About Piper!
        </p>
      </div>
      <div class={bodyParentClass}>
        <p class={bodyClass} id="about-me-greeting">
          Hey everyone, my name is Piper!
        </p>
      </div>
      <div class={`${bodyParentClass} flex flex-col items-center content-center justify-center`}>
        <p class={`${bodyFormatedClass} pb-4 `} id="about-me-info-1">
          I am so glad that you are here at my page. It has been a dream of mine, since I was 2, to
          create a YT channel. I am getting close to 6 now, and my family decided it was time to
          start. We literally have no idea what we are doing. Those of you that are here, get to
          experience the entire learning process. I will add new videos to my YT channel as my
          family learns and can. My webpage will be updated as frequently as possible by my dad. I
          highly recommend Subscribing to my YT channel in the links below.
        </p>
        <p class={`${bodyFormatedClass} pb-4 `} id="about-me-info-2">
          Now, a little bit more about me. I am 5 years old. I grew up in Utah, but my family
          decided to move to the Evergreen State! I really love it here. I get to take walks in the
          forest with my dog, we like to look for squirrels and deer. I get to go to the beach and
          see the ocean!
        </p>
        <p class={`${bodyFormatedClass}`} id="about-me-info-3">
          When I am not on a new adventure, I love creating new and amazing art work. I also really
          like to beat box, and am learning how to make new sounds all the time. I hope to share
          these talents with you soon! Definitely want to share more about myself, so go ahead and
          check out my channel in the links below, and make sure to subscribe to find out all about
          me!
        </p>
      </div>
    </div>
  );
}
