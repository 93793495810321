import ReactPlayer from "react-player";
import { FavoriteYouTubers, MyChannel } from "../components/YouTubers";
import { bodyClass, imageParentClass, pageClass, videoPlayerClass } from "../tailwindcss/css";

export default function Videos() {
  return (
    <div class={pageClass}>
      <p class={`${bodyClass} p-4`} id="my-channel">
        Check out my channel!
      </p>
      <MyChannel />
      <p class={`${bodyClass} p-4`} id="my-channel-first-video">
        Checkout My First Video!
      </p>
      <YouTubePlayer />
      <p class={`${bodyClass} p-4`} id="favorite-youtubers">
        Check out some of my favorite YouTubers!
      </p>
      <FavoriteYouTubers />
    </div>
  );
}

function YouTubePlayer() {
  return (
    <div class={imageParentClass}>
      <ReactPlayer class={videoPlayerClass} url="https://youtu.be/RsyWLLxXks8" />
    </div>
  );
}
