import { Link } from "./LinkFactory";
import { linkParentClass } from "../tailwindcss/css";

export default function ContactMe() {
  return (
    <span class={`${linkParentClass} p-4`}>
      <span></span>
      <Link
        url="mailto:piperbrass2016@gmail.com?subject='Hi Piper! I wanted to ask a question!'"
        name="Contact Me!"
      />
      <span></span>
    </span>
  );
}
