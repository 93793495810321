export const youtubers = [
  {
    name: "Piper's Forest Fam",
    id: "piper-forest-fam",
    group: "primary",
    url: "https://www.youtube.com/channel/UCY_fYFIzxoE5yU_hWciAAMg",
    img: "https://yt3.ggpht.com/yfUi-hUhIgnDfUxcP_5NIQe1M7iFcAGoYZpJMXcxhZ75zyRn2RX-JA7S0uk9edFTtn7WOSe3=s176-c-k-c0x00ffffff-no-rj",
  },
  {
    name: "Lyssy Noel",
    id: "lyssy-noel",
    group: "favorite",
    url: "https://www.youtube.com/c/LyssyNoel",
    img: "https://yt3.ggpht.com/ytc/AKedOLS2erGqdiBQXCJdsLPCffnyL0-TAPNXCikoF40ZxQ=s176-c-k-c0x00ffffff-no-rj",
  },
  {
    name: "Jester",
    id: "jester",
    group: "favorite",
    url: "https://www.youtube.com/c/JesseBaron",
    img: "https://yt3.ggpht.com/ytc/AKedOLQFBACOf6uD2Pe3UKrFP-65gCLUrtFre9Vfl9zu-w=s176-c-k-c0x00ffffff-no-rj",
  },
  {
    name: "SSSniperWolf",
    id: "sssniperwolf",
    group: "favorite",
    url: "https://www.youtube.com/c/SSSniperWolf",
    img: "https://yt3.ggpht.com/ytc/AKedOLSfCNPqf0UDhWv8I5EnhE011pWhqN1HrcFWsDilZw=s176-c-k-c0x00ffffff-no-rj",
  },
];

export const [piper, lyssynoel, jester, sssniperwolf] = youtubers;
